import React, { useRef, useState, useLayoutEffect, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import HeroImg from "../assets/HeroImg.webp";
import Navbar from "./Navbar";
import GoogleMobile from "../assets/Google-Pixel-4awebp.webp";
import HeroImage from "../assets/desktop.webp";
import ImageSix from "../assets/image-65-webp.webp";
import HeroTyped from "./HeroTyped";
import { useCountUp } from "react-countup";
import useWebAppNavigation from "../utils/UseWebAppNavigation";
import { trackGetStartedNow } from "../utils/analytics";
import { ShimmerButton } from "../utils/AnimatedBtn";
// screens: {
//   'sm': '640px',
//   // => @media (min-width: 640px) { ... }

//   'md': '1070px',
//   // => @media (min-width: 768px) { ... }

//   'lg': '1024px',
//   // => @media (min-width: 1024px) { ... }

//   'xl': '1280px',
//   // => @media (min-width: 1280px) { ... }

//   '2xl': '1536px',
//   // => @media (min-width: 1536px) { ... }
// }

const Hero = ({ setIsSideBarOpen }) => {
  const heroSectionStyle = {
    backgroundImage: `url(${HeroImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  // eslint-disable-next-line no-unused-vars
  const [_shouldCount, setShouldCount] = useState(false); // Set initial value to true

  const heroRef = useRef(null);
  const titleRef = useRef(null);
  const buttonRef = useRef(null);
  const statsRef = useRef(null);
  const titleLines = useRef([]);

  const { navigateTo } = useWebAppNavigation();

  const CountUpWrapper = ({ end, duration, decimals }) => {
    const countUpRef = useRef(null);
    const { start, reset } = useCountUp({
      ref: countUpRef,
      start: 0,
      end: end,
      duration: duration,
      decimals: decimals,
    });

    useEffect(() => {
      // console.log('CountUpWrapper mounted, starting count');
      start();
      return () => {
        // console.log('CountUpWrapper unmounted');
        reset();
      };
    }, [start, reset]);

    return <span ref={countUpRef} />;
  };

  useLayoutEffect(() => {
    ScrollTrigger.refresh();
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }
    const hero = heroRef.current;
    //const title = titleRef.current;
    const button = buttonRef.current;
    //const stats = statsRef.current;

    // gsap.fromTo(
    //   title,
    //   { opacity: 0 },
    //   { opacity: 1, duration: 0.5, scrollTrigger: { trigger: hero } }
    // );
    // gsap.fromTo(
    //   button,
    //   { opacity: 0 },
    //   { opacity: 1, duration: 1, delay: 0.6, scrollTrigger: { trigger: hero } }
    // );

    // gsap.fromTo(
    //   stats.children,
    //   { scale: 0.3, opacity: 0 },
    //   {
    //     scale: 1,
    //     opacity: 1,
    //     duration: 0.8,
    //     stagger: 0.2,
    //     scrollTrigger: { trigger: hero },
    //   }
    // );

    // Updated button animation
    const mediaQueryT = window.matchMedia("(min-width: 1024px)");

    // gsap.set(titleLines.current, { y: 50, opacity: 0 });
    // gsap.to(titleLines.current, {
    //   y: 0,
    //   opacity: 1,
    //   duration: 0.8,
    //   stagger: {
    //     each: 0.2,
    //   },
    //   ease: "power3.out",
    //   scrollTrigger: {
    //     markers: true,
    //     trigger: titleRef.current,
    //     start: "top 70%",
    //   },
    // });

    if (mediaQueryT.matches) {
      //    // Text animation
      ScrollTrigger.refresh();
      // animations for large screens
      const largeTimeLine = gsap.timeline();

      largeTimeLine.set(titleLines.current, { y: 60, opacity: 0 });
      largeTimeLine.to(titleLines.current, {
        y: 0,
        opacity: 1,
        duration: 0.6,
        delay: 1,
        stagger: {
          each: 0.2,
        },
        ease: "power3.out",
      });

      // Pop-up animation for large screens
      largeTimeLine.fromTo(
        button,
        { scale: 0.6, opacity: 0, visibility: "hidden" },
        {
          scale: 1,
          opacity: 1,
          visibility: "visible",
          duration: 0.4,
          delay: 1,
          ease: "elastic.out(1, 0.5)",
          scrollTrigger: { trigger: hero },
        },
        "-=1.3"
      );
      // image one
      largeTimeLine.fromTo(
        "#mediumImageOne",
        {
          opacity: 0,
          x: -500,
        },
        {
          opacity: 1,
          x: 0,
          duration: 0.6,
        }
        // "-=0.4"
      );

      // image two
      // largeTimeLine.fromTo(
      //   "#mediumImageTwo",
      //   {
      //     opacity: 0,
      //     y: 500,
      //   },
      //   {
      //     opacity: 1,
      //     y: 0,
      //     duration: 0.7,
      //   }, "-=0.4"
      // );

      largeTimeLine.fromTo(
        "#mediumImageThree",
        {
          opacity: 0,
          x: 500,
          force3D: true,
        },
        {
          opacity: 1,
          x: 0,
          duration: 0.6,
          // clearProps: "transform",
        }
      );
    } else {
      ScrollTrigger.refresh();
      const largeTimeLine = gsap.timeline();

      largeTimeLine.set(titleLines.current, { y: 20, opacity: 0 });
      largeTimeLine.to(titleLines.current, {
        y: 0,
        opacity: 1,
        delay: 1,
        duration: 0.5,
        stagger: {
          each: 0.2,
        },
        ease: "power3.out",
      });
    }
    // animation for medium
    const mediaQueryMobile = window.matchMedia("(max-width: 1070px)");
    // console.log('small screen')
    if (mediaQueryMobile.matches) {
      gsap.fromTo(
        "#ImageOne",
        {
          opacity: 0,
          duration: 1,
          x: -500,
        },
        {
          scrollTrigger: {
            // markers: true,
            trigger: "#ImageOne",
            start: "top bottom-=15%",
            end: "top bottom-=135%",
            toggleActions: "play none play reverse",
          },
          opacity: 1,
          duration: 0.6,
          x: 0,
        }
      );

      gsap.fromTo(
        "#ImageTwo",
        {
          opacity: 0,
          duration: 1,
          y: 500,
        },
        {
          scrollTrigger: {
            trigger: "#ImageOne",
            start: "top bottom-=10%",
            end: "top bottom-=135%",
            toggleActions: "play none play reverse",
          },
          opacity: 1,
          duration: 0.7,
          y: 0,
        }
      );

      gsap.fromTo(
        "#ImageThree",
        {
          opacity: 0,
          duration: 1,
          x: 500,
        },
        {
          scrollTrigger: {
            trigger: "#ImageOne",
            start: "top bottom-=10%",
            end: "top bottom-=135%",
            toggleActions: "play none play reverse",
          },
          opacity: 1,
          duration: 0.6,
          x: 0,
        }
      );
    }

    // Remove the ScrollTrigger for counter reset
    // Instead, we'll use the IntersectionObserver API

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShouldCount(true);
        } else {
          setShouldCount(false);
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }

      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, []);

  const navigateToDocuments = () => {
    trackGetStartedNow();
    navigateTo("documents");
  };

  return (
    <section>
      <Navbar setIsSideBarOpen={setIsSideBarOpen} />
      <section
        ref={heroRef}
        className=" md:mx-[80px] overflow-hidden mx-[16px] pt-[112px] pb-[40px]"
      >
        <main className="md:w-[78%] w-full m-auto">
          <h1 className="md:text-6xl text-4xl md:leading-[72px] leading-[48px] text-center font-[Satoshi-Black] text-[#1B1E21] font-black">
            Review & Re-write Your
            <span className="text-[#9357EA]"> Resume</span> with Instant AI
            Feedback
          </h1>

          <div className="md:mx-[50px]">
            <p className="text-lg md:block hidden pt-4 text-center font-[Satoshi-Regular] text-[#515B6F] leading-[28px] font-normal ">
              Upload your resume, receive immediate expert feedback, and access
              tailored rewrites in seconds. Ensure every application is strong
              and tailored, helping you land more interviews.
            </p>
            <p className="md:text-lg text-base md:hidden tracking-[0.16px] block pt-4 text-center font-[Satoshi-Regular] text-[#515B6F] md:leading-[28px] leading-6 font-normal ">
              Accelerate Your job search with a well crafted winning resumes,
              track applications effortlessly, and land your dream job with
              ease.
            </p>
          </div>

          <div className="md:space-x-6 flex md:flex-row flex-col-reverse justify-center mt-10 text-center">
            <ShimmerButton
              className="rounded md:block hidden"
              background="#000"
            >
              <a
                href="https://www.app.swiftcareer.co/home/resume-review"
                target="_blank"
              >
                <button className="py-4 md:mt-0 mt-4 bg-[#1B1E21] md:w-[170px] w-full rounded text-base font-[Satoshi-Bold] text-white  px-5">
                  Upload resume
                </button>
              </a>
            </ShimmerButton>
            <ShimmerButton
              className="rounded md:block hidden"
              background="#9357EA"
            >
              <a
                href="https://www.app.swiftcareer.co/home/documents?activeTab=resume"
                target="_blank"
              >
                <button className="py-4 bg-[#9357EA] md:w-[184px] w-full rounded text-base font-[Satoshi-Bold] text-white  px-5">
                  Create my resume
                </button>
              </a>
            </ShimmerButton>

            <a
              className="md:hidden block"
              href="https://www.app.swiftcareer.co/home/resume-review"
              target="_blank"
            >
              <button className="py-4 md:mt-0 mt-4 bg-[#1B1E21] md:w-[170px] w-full rounded text-base font-[Satoshi-Bold] text-white  px-5">
                Upload resume
              </button>
            </a>

            <a
              className="md:hidden block"
              href="https://www.app.swiftcareer.co/home/documents?activeTab=resume"
              target="_blank"
            >
              <button className="py-4 bg-[#9357EA] md:w-[184px] w-full rounded text-base font-[Satoshi-Bold] text-white  px-5">
                Create my resume
              </button>
            </a>
          </div>

          <div className="flex flex-col mt-6 w-full text-center">
            <h1 className="text-2xl text-[#1B1E21] font-[Satoshi-Black]">
              <CountUpWrapper end={250} duration={10} />+
            </h1>
            <p className="text-base font-[Satoshi-Regular] text-[#515B6F]">
              Resume & Cover letter created
            </p>
          </div>
        </main>

        {/* imageSection */}
        <img src={ImageSix} className="md:hidden mt-8" alt="ImageSix" />
        <img className="md:flex mt-10 hidden" src={HeroImage} alt="HeroImage" />
      </section>
    </section>
  );
};

export default Hero;
