import React, { useState, useEffect, Suspense } from 'react'
import Hero from './Components/Hero.jsx'
import CompaniesHiredApp from './Components/CompaniesHiredApp.jsx'
import EquipwithAi from './Components/EquipwithAi.jsx';
import './utils/firebase_analytics_setup.js'
// import ChooseProfessionalRe from './components/ChooseProfessionalRe';
import ReviewedByComm from './Components/ReviewedByComm.jsx';
import Footer from './Components/Footer.jsx';
import Frequently from './Components/Frequently.jsx';
import Features from './Components/Features.jsx';
import InterviewPreparation from './Components/InterviewPreparation.jsx';
import CreatingAiWinning from './Components/CreatingAiWinning.jsx';
import StandOutFromCrowd from './Components/StandOutFromCrowd.jsx';
import SideBar from './Components/SideBar.jsx';
const App = () => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  useEffect(() => {
    if (isSideBarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isSideBarOpen]);

  return (
    <div className='overflow-hidden'>
      {/* Load Hero immediately */}
      <Hero setIsSideBarOpen={setIsSideBarOpen} />
      {isSideBarOpen && <SideBar className="hidden" isOpen={isSideBarOpen} onClose={() => setIsSideBarOpen(false)} />}
      <Suspense fallback={<div>Loading...</div>}>
      <CompaniesHiredApp />
      <EquipwithAi />
      <Features />
      <InterviewPreparation />
      {/* <ChooseProfessionalRe /> */}
      <ReviewedByComm />
      <CreatingAiWinning />
      <StandOutFromCrowd />
      <Frequently />
      <Footer />
      </Suspense>
    </div>
  )
}

export default App


